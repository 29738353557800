import { Keyboard } from "@capacitor/keyboard";
import { InputChangeEventDetail, isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { FC, FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentFirstName: FC<OnBoardingComponentProps> = ({ agent }) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(agent?.firstName ?? "");
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });

  const history = useHistory();

  const onFirstNameChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const firstName = event
      .detail!.value!.toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    setFirstName(firstName.trim());
  };

  const onNext = async (event?: FormEvent | MouseEvent) => {
    try {
      setFirstName(firstName.trim());
      event && event.preventDefault();
      if (isPlatform("capacitor")) {
        Keyboard.hide();
      }
      setErrorAlert({ hasError: false });
      setUpdatingData(true);
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_FIRST_NAME, {
        hcpId: agent?.userId as string,
        hcpEventValue: firstName,
      });
      history.push("/home/agentLastName", { firstName, lastName: agent?.lastName ?? "" });
    } catch (error) {
      setErrorAlert({ hasError: true, reason: error?.message });
    } finally {
      setUpdatingData(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentEmail" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert?.reason}
          closeAlert={closeErrorModal}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol>
                  <div className="form-heading">
                    <h4>What's your first name?</h4>
                  </div>
                  <div>
                    <IonLabel className="form-label">Legal First Name</IonLabel>
                    <IonInput
                      data-testid="firstNameInput"
                      className="form-input"
                      type="text"
                      placeholder="First Name"
                      onIonChange={onFirstNameChange}
                      value={firstName}
                      autofocus
                      required
                    />
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={onNext}
              disabled={updatingData || (firstName?.trim() ?? "").length < 2}
            >
              Continue
              {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentFirstName };
