import { Keyboard } from "@capacitor/keyboard";
import { InputChangeEventDetail, isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { FC, FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { updateAgentData } from "./api";
import { LastNameLocationStateProps, OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { OIG } from "../constants/OIG_VALIDATION";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { OigResponse } from "../util/oig";
import { fireOnboardingSegmentEvent } from "../util/segment";

export const AgentLastName: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();

  const { firstName, lastName: initialLastName = "" } =
    (history.location.state as LastNameLocationStateProps) || {};

  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string | undefined>(initialLastName);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  const { showErrorToast } = useToast();

  useEffect(() => {
    if (!firstName) {
      history.push("/home/agentFirstName");
    }
  }, [history, firstName]);

  const onLastNameChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const lastName = event
      .detail!.value!.toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    setLastName(lastName.trim());
  };

  const onNext = async (event?: FormEvent | MouseEvent) => {
    setLastName(lastName?.trim());
    event && event.preventDefault();
    if (isPlatform("capacitor")) {
      Keyboard.hide();
    }
    setErrorAlert({ hasError: false });
    try {
      setUpdatingData(true);
      await getFirebaseSingleton().updateCurrentUserName(`${firstName} ${lastName}`);
      try {
        const updateAgentPayload = {
          stage: ONBOARDING_STAGES.NAME,
          firstName,
          lastName,
          requestOigValidation: true,
        };

        /**
         * FIXME - remove API state transfer to localStorage. Convert to `react-query`
         * Also - find a better name for oig. What is it?
         */
        const response = await updateAgentData(updateAgentPayload);

        const oig = response[OIG] ? (response[OIG] as OigResponse) : null;
        if (oig?.requestFurtherValidation) {
          localStorage.setItem(
            OIG,
            JSON.stringify({
              validationId: oig.validationId,
              dobRequired: true,
            })
          );
        } else {
          localStorage.removeItem(OIG);
        }

        fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LAST_NAME, {
          hcpId: agent?.userId as string,
          hcpEventValue: lastName,
        });
        history.push(`${nextStagePath}`);
      } catch (err) {
        if (!err.response || !err.response.text || err.response.status > 500) {
          logOnboardingError(ONBOARDING_STAGES.NAME, (err as Error).message, agent?.userId);
          setErrorAlert({ hasError: true, reason: err?.message });
          return;
        }
        showErrorToast(err.response.text);
      }
    } catch (error) {
      setErrorAlert({ hasError: true, reason: error?.message });
    } finally {
      setUpdatingData(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentFirstName/" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert?.reason}
          closeAlert={closeErrorModal}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol>
                  <div className="form-heading">
                    <h4>What's your last name?</h4>
                  </div>
                  <div>
                    <IonLabel className="form-label">Legal Last Name</IonLabel>
                    <IonInput
                      className="form-input"
                      type="text"
                      placeholder="Last Name"
                      onIonChange={onLastNameChange}
                      value={lastName}
                      autofocus
                      required
                    />
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={onNext}
              disabled={updatingData || (lastName?.trim() ?? "").length < 2}
            >
              Continue
              {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};
