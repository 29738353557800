import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { ShiftDateMap } from "@src/app/dayView/model";
import { pluralHours } from "@src/app/utils/strings";
import { ShiftChip, getHasShiftColor } from "@src/lib/deprecatedCode";
import { Shift } from "@src/lib/interface";
import {
  chevronForwardOutline,
  cloudy,
  cloudyNight,
  informationCircleOutline,
  moon,
  navigateOutline,
  partlySunny,
  starOutline,
} from "ionicons/icons";
import { groupBy } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { fetchOpenShifts } from "../../openShifts/api";
import { groupByShiftName } from "../../store/shift";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentPotentialShifts: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();
  const [isAccordOpen, setAccordOpen] = useState<string>("");
  const [isFetchingShifts, setFetchingShifts] = useState<boolean>(true);
  const [openShiftMap, setOpenShiftMap] = useState<ShiftDateMap>({});
  const [showShiftSelectionAlert, setShowShiftSelectionAlert] = useState<boolean>(false);

  /**
   * switches to the next page
   * @param replaceHistory whether to unregister this page from history
   */
  const onNext = (replaceHistory = false) => {
    if (replaceHistory) {
      history.replace(nextStagePath as string);
    } else {
      history.push(nextStagePath as string);
    }
  };

  // Main Confetti Function
  const confetti = (confettiCount) => {
    const confettiColor = "#1C69D1";
    // Random Number Function
    const rnd = (m, n) => {
      m = parseInt(m);
      n = parseInt(n);
      return Math.floor(Math.random() * (n - m + 1)) + m;
    };

    const confettiSpray: HTMLSpanElement[] = [];
    // Create random individual confetti pieces and append to dom
    for (let i = 0; i <= confettiCount; i++) {
      // Create confetti pieces
      const confetti = document.createElement("span");
      confetti.className = "confetti particle";
      confetti.style.backgroundColor = confettiColor;
      confetti.style.top = rnd(10, 50) + "%";
      confetti.style.left = rnd(0, 100) + "%";
      confetti.style.animationDelay = rnd(0, 10) / 10 + "s";
      confettiSpray.push(confetti);
    }
    document.getElementById("confetti-wrapper")?.append(...confettiSpray);
  };

  const today = moment().format("YYYY-MM-DD");
  const shiftOptions = {
    range: { start: today, end: moment().add(1, "week").format("YYYY-MM-DD") },
    coordinates: agent?.geoLocation?.coordinates ?? [],
    distance: agent?.preference?.distance ?? 100,
    limitResults: true,
  };

  const getShiftIcon = (shiftName: string) => {
    switch (shiftName) {
      case "am":
        return partlySunny;
      case "pm":
        return cloudyNight;
      case "noc":
        return moon;
      default:
        return cloudy;
    }
  };

  const getTimeOfDay = {
    am: "Morning",
    pm: "Evening",
    noc: "Night on call",
  };

  useEffect(() => {
    (async () => {
      try {
        const openShifts: Shift[] | undefined = await fetchOpenShifts(shiftOptions);

        if (!openShifts?.length) {
          onNext(true);
          return;
        }

        const potentialOpenShifts = groupBy(openShifts, groupByShiftName);
        setOpenShiftMap({
          ...(potentialOpenShifts.am ? { am: potentialOpenShifts.am } : {}),
          ...(potentialOpenShifts.pm ? { pm: potentialOpenShifts.pm } : {}),
          ...(potentialOpenShifts.noc ? { noc: potentialOpenShifts.noc } : {}),
        });
        setFetchingShifts(false);
      } catch (error) {
        setFetchingShifts(false);
        onNext(true);
      }
    })();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetchingShifts && Object.keys(openShiftMap).length) {
      const confettiCount = (document.documentElement.clientWidth || window.innerWidth || 400) / 6;

      confetti(confettiCount);
    }
  }, [isFetchingShifts, openShiftMap]);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentSignupInfo2" mode="ios" color="dark" />
          </IonButtons>
          <div className="cbh-logoh-wrapper">
            <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
          </div>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        isOpen={showShiftSelectionAlert}
        onDidDismiss={() => setShowShiftSelectionAlert(false)}
        header="Almost There"
        message="We are excited too, just two more steps."
        buttons={[
          {
            text: "Finish Signup",
            handler: onNext,
          },
        ]}
      />

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          {isFetchingShifts || !Object.keys(openShiftMap).length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <img
                width="250"
                height="250"
                src="/assets/gifs/loadingAvailableShifts.gif"
                alt="Loading available shifts"
              />
            </div>
          ) : (
            <div className="form-container">
              <IonRow>
                <div id="confetti-wrapper"></div>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="form-heading">
                    <h4>We’ve got shifts in your area!</h4>
                    <p>Here’s a sneak peak of what to expect ☺️</p>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h4 className="potentialShift-date">Shifts Coming Up</h4>
                  <div className="openShifts-wrapper">
                    {Object.keys(openShiftMap).map((shiftName) => {
                      const openShiftLength = openShiftMap[shiftName]?.length ?? 0;
                      const hasShift = {
                        hasOpenShift: openShiftLength > 0,
                        hasStaffedShift: false,
                        hasPriorityShift: false,
                      };
                      const description = `${openShiftMap[shiftName].length} ${getTimeOfDay[shiftName]} shifts`;
                      return (
                        <div
                          key={shiftName}
                          className={`form-item-wrapper shadowedCard pShift-card accordion${
                            isAccordOpen === shiftName ? ` active` : ""
                          }`}
                          onClick={() => setAccordOpen(shiftName)}
                          role="button"
                          tabIndex={0}
                        >
                          <IonItem lines="none">
                            <IonIcon
                              icon={getShiftIcon(shiftName)}
                              mode="ios"
                              color={getHasShiftColor(hasShift, shiftName, true)}
                              slot="start"
                            />
                            <IonLabel>
                              <ShiftChip name={shiftName} hasShift={hasShift} outline={true} />
                              <IonText color="dark">
                                <p className="shiftcard-text">{description}</p>
                              </IonText>
                            </IonLabel>
                            <IonIcon
                              icon={chevronForwardOutline}
                              slot="end"
                              mode="ios"
                              color="medium"
                              className={isAccordOpen === shiftName ? `active` : ""}
                            />
                          </IonItem>
                          <div className="shiftName-details-wrap">
                            {isAccordOpen === shiftName && (
                              <>
                                {openShiftMap[shiftName].map((shift) => (
                                  <IonItem
                                    lines="none"
                                    key={shift._id}
                                    className="shifts-list"
                                    onClick={() => {
                                      setShowShiftSelectionAlert(true);
                                      fireOnboardingSegmentEvent(
                                        ONBOARDING_SEGMENT_EVENT_NAMES.SELECTED_EXAMPLE_SHIFT,
                                        {
                                          hcpId: agent?.userId as string,
                                        }
                                      );
                                    }}
                                  >
                                    <IonIcon
                                      icon={starOutline}
                                      mode="ios"
                                      color="medium"
                                      slot="start"
                                    />
                                    <IonLabel>
                                      <div className="shift-details">
                                        <p className="shift_time shiftcard-text">
                                          {moment(shift.start).format("hh:mm a")} -{" "}
                                          {moment(shift.end).format("hh:mm a")} ({shift.time}{" "}
                                          {pluralHours(shift)})
                                        </p>
                                        <p className="shift_date">
                                          {moment(shift.start).format("MM-DD-YY")}
                                        </p>
                                        <p className="shift_pay">
                                          <span>${shift.finalPay} per hour</span> |
                                          <span>{shift.agentReq}</span>
                                        </p>
                                        <p className="facility_name">{shift?.facility?.name}</p>
                                        <p className="facility_address">
                                          {shift?.facility?.fullAddress?.formatted}
                                        </p>
                                        {shift["distance"] && (
                                          <IonItem lines="none" className="facility_distance">
                                            <IonIcon
                                              icon={navigateOutline}
                                              mode="ios"
                                              color="medium"
                                              slot="start"
                                            />
                                            <p>{shift["distance"].toFixed(2)} miles away</p>
                                          </IonItem>
                                        )}
                                      </div>
                                    </IonLabel>
                                    <IonIcon
                                      icon={informationCircleOutline}
                                      mode="ios"
                                      color="medium"
                                      slot="end"
                                    />
                                  </IonItem>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </IonCol>
              </IonRow>
            </div>
          )}

          <div className="signupform-footer footer-container">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                onNext();
              }}
            >
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                onClick={(event) => {
                  event.preventDefault();
                  onNext();
                }}
                disabled={isFetchingShifts}
              >
                Get set up to get paid
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentPotentialShifts };
